<i18n>
ru:
  modString: '{count} x {mod}'
  mods: 'Дополнительно:'
  piece: шт
  pricePerPiece: цена за шт
  subtotal: итого
ua:
  modString: '{count} x {mod}'
  mods: 'Додатково:'
  piece: шт
  pricePerPiece: ціна за шт
  subtotal: разом
us:
  modString: '{count} x {mod}'
  mods: 'Additional:'
  piece: items
  pricePerPiece: Price per PCs.
  subtotal: Subtotal
</i18n>

<template>
  <div
    v-if="appConfig.VueSettingsPreRun.OrderCompleteView !== 'compact'"
    class="v-order-complete-items v-order-complete-items-legend v-text-center"
  >
    <span />
    <span />
    <span v-html="translate('orderCompleteItems.pricePerPiece')" />
    <span v-html="translate('orderCompleteItems.piece')" />
    <span v-html="translate('orderCompleteItems.subtotal')" />
  </div>
  <div
    v-for="(item, index) in items"
    :key="`item-${index}`"
    class="v-order-complete-items v-order-complete-items-single"
    :class="{
      'v-order-complete-compact':
        appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
    }"
  >
    <div class="v-order-complete-item-image">
      <arora-nuxt-image
        :alt="item.Name"
        :image="item.SmallImage"
        image-type="ProductSmall"
      />
    </div>
    <div class="v-order-complete-item-name-and-desc">
      <div
        class="v-order-complete-item-name"
        :class="{
          'v-order-complete-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
        v-html="sanitize(item.Name)"
      />
      <div
        class="v-order-complete-item-name__desc"
        :class="{
          'v-order-complete-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
        v-html="sanitize(item.OptionName)"
      />
      <div
        v-if="
          appConfig.VueSettingsPreRun.OrderCompleteShowProductDesc && item.Description
        "
        class="v-order-complete-item-description"
        :class="{
          'v-order-complete-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
      >
        <b
          v-if="!item.IsCombine"
          v-html="translate('itemsPage.productDescription')"
        />
        <span
          v-if="item.DescriptionType === ProductDescriptionType.RemovableIngredients"
          v-html="
            getDescriptionWithoutRemovedIngredients(
              item,
              removedIngredientsByProduct[index]
            )
          "
        />
        <span
          v-else
          v-html="sanitize(item.Description)"
        />
      </div>
      <div
        v-if="modifiersWithoutIngredientsByProduct[index].trim().length > 0"
        class="v-order-complete-item-modifiers"
        :class="{
          'v-order-complete-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
      >
        <b v-html="translate('orderCompleteItems.mods')" />
        <span
          v-for="(modCount, modID) in modifiersWithoutIngredientsByProduct[index]"
          :key="`mod-${modID}`"
          v-html="modCount"
        />
      </div>
      <div
        v-if="removedIngredientsByProduct[index].length > 0"
        class="v-order-complete-ingredients v-mr-xs"
        :class="{
          'v-order-complete-compact':
            appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
        }"
      >
        <span v-html="translate('itemsPage.removableIngredientsPrefix')" />
        <span
          v-for="(ing, ingIndex) in removedIngredientsByProduct[index]"
          :key="`${sanitize(item.ProductID)}-${sanitize(ing.ModifierId)}`"
        >
          <span
            class="v-order-complete-ingredients__ingredient"
            v-html="clean(ing.Title)"
          />
          <span
            v-if="ingIndex !== removedIngredientsByProduct[index].length - 1"
            v-html="'; '"
          />
        </span>
      </div>
    </div>

    <div
      class="v-order-complete-mobile-header v-text-center"
      v-html="translate('itemsPage.pricePerPiece')"
    />
    <div
      class="v-order-complete-mobile-header v-text-center"
      v-html="translate('quantityMeasure.piece')"
    />
    <div
      class="v-order-complete-mobile-header v-text-center"
      v-html="translate('itemsPage.subtotal')"
    />

    <div
      class="v-order-complete-item-price"
      :class="{
        'v-order-complete-compact':
          appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
      }"
    >
      <common-currency
        v-if="item.PriceInPoints === 0"
        class="v-d-flex v-align-items-center"
        :amount="item.PriceFullModified"
      />
      <common-currency
        v-else
        class="v-d-flex v-align-items-center"
        show-points
        :amount="item.PriceInPoints"
      />
    </div>

    <div
      class="v-order-complete-item-num"
      :class="{
        'v-order-complete-compact':
          appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
      }"
    >
      <common-quantity-measure
        :measure="piece"
        :quantity="item.Count"
      />
    </div>

    <div
      class="v-order-complete-item-price"
      :class="{
        'v-order-complete-compact':
          appConfig.VueSettingsPreRun.OrderCompleteView === 'compact'
      }"
    >
      <common-currency
        v-if="item.PriceInPoints === 0"
        class="v-d-flex v-align-items-center"
        :amount="item.PriceFullModified * item.Count"
      />
      <common-currency
        v-else
        class="v-d-flex v-align-items-center"
        show-points
        :amount="item.PriceInPoints * item.Count"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OrderInfoItem } from '~types/clientStore'
import type { RemovableIngredient } from '~types/menuStore'

import { ProductDescriptionType, ProductMeasureType } from '~api/consts'

const { items } = defineProps<{ items: OrderInfoItem[] }>()

const appConfig = useAppConfig()

const { clean, sanitize, translate } = useI18nSanitized()
const {
  getDescriptionWithoutRemovedIngredients,
  getModifiersWithoutIngredientsByProduct,
  getRemovedIngredientsByProduct
} = useProduct()

const piece: number = ProductMeasureType.Piece

const removedIngredientsByProduct: Record<number, RemovableIngredient[]> =
  getRemovedIngredientsByProduct(items)

const modifiersWithoutIngredientsByProduct: Record<number, string> =
  getModifiersWithoutIngredientsByProduct(items, removedIngredientsByProduct)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-order-complete-items {
  display: grid;
  grid-template-columns: 2fr 5fr 2fr 1.5fr 2fr;
  gap: 0 15px;
  align-items: center;
  position: relative;
  padding: 15px;

  @include mixins.sm {
    grid-row-start: auto;
    grid-template-columns: repeat(4, 23%);
  }

  &.v-order-complete-compact {
    padding: 10px;
    gap: 0 10px;
  }
}

.v-order-complete-items-single {
  background: rgba(175, 175, 175, 0.1);
  border: 1px solid variables.$BorderColorLight;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  margin-bottom: 1.1rem;
}

.v-order-complete-items-legend {
  color: variables.$BodyTextColorLight;
  font-size: 12px;

  @include mixins.sm {
    display: none;
  }
}

.v-order-complete-mobile-header {
  display: none;
  color: variables.$BodyTextColorLight;
  font-size: 10px;

  @include mixins.sm {
    display: grid;
  }
}

.v-order-complete-item-image {
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;

  @include mixins.sm {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.v-order-complete-item-name-and-desc {
  @include mixins.sm {
    grid-column: 2 / 5;
    border-bottom: 1px solid variables.$BorderColor;
    margin-bottom: 10px;
  }
}

.v-order-complete-item-name {
  font-size: 1.4rem;
  padding: 10px;
  &__desc {
    font-size: 0.9rem;
    padding: 0 10px;
    opacity: 0.7;
    &.v-order-complete-compact {
      padding: 0 5px;
    }
  }
  &.v-order-complete-compact {
    font-size: 16px;
    padding: 5px;
  }
}

.v-order-complete-item-modifiers {
  font-size: variables.$TextSizeMain;
  padding: 10px;

  &.v-order-complete-compact {
    font-size: 12px;
    padding: 5px;
  }

  b {
    margin-right: 5px;
  }
}

.v-order-complete-item-description {
  font-size: variables.$TextSizeMain;
  padding: 10px;
  color: variables.$BodyTextColorLight;

  &.v-order-complete-compact {
    font-size: 12px;
    padding: 5px;
  }

  b {
    margin-right: 5px;
  }
}

.v-order-complete-ingredients {
  font-size: variables.$TextSizeMain;
  padding: 10px;

  &.v-order-complete-compact {
    font-size: 12px;
    padding: 5px;
  }

  &__ingredient {
    text-decoration: line-through;
  }
}

.v-order-complete-item-price {
  display: flex;
  padding: 15px;
  font-size: 20px;
  text-align: center;
  justify-content: center;

  &.v-order-complete-compact {
    font-size: 16px;
    padding: 10px;
  }

  .v-strikethrough {
    margin-right: 10px;
  }
}

.v-order-complete-item-num {
  padding: 15px;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  &.v-order-complete-compact {
    font-size: 16px;
    padding: 10px;
  }
}
</style>
